<template>
  <section
    id="welcome"
    class="hide-overflow"
  >
    <v-layout>
      <!-- <v-flex
        hidden-sm-and-down
        md6
      > -->
        <!-- <v-img
          :src="require('@/assets/welcome.png')"
          height="10vh"
        /> -->
      <!-- </v-flex> -->

      <v-flex
        xs12
        align-content-space-between
        layout
        :pa-5="breakpoint"
        wrap
      >
        <!-- <base-bubble-1
          style="transform: rotate(180deg) translateY(25%)"
        /> -->

        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs10
            md6
          >
            <base-heading>UBUNIFU</base-heading>
            <base-text>
              {{ $t("message.title") }}
            </base-text>
            <!-- <p>{{ $t("message.hello") }}</p> -->
            <!-- <base-btn class="mt-4">
              Learn More!
            </base-btn> -->
          </v-flex>
        </v-layout>

        <!-- <base-bubble-2
          style="transform: rotate(180deg) translate(-200px, -15%)"
        /> -->
      </v-flex>
    </v-layout>
  </section>
</template>
<script>
export default {
  computed: {
    breakpoint () {
      return this.$vuetify.breakpoint.smAndDown
    }
  }
}
</script>
